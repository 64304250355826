// @ts-nocheck
"use strict";
// import Renderer from "./core/render.ts";
import { buffer } from "stream/consumers";
import Renderer from "./core/render/index.ts";
import Painting from "./core/painting/index.ts";
import type { Mesh } from "./types.d.ts";
import { makeMesh } from "./algorithm/mesh.ts";

import Sliders from "./plugin/sliders.ts";

import param from "./config/index.ts";
// import MapWorker from "./service/index.ts";
import MapWorkerService from "./service/index.ts";
import { WorkerMessageType } from "./service/types.ts";

import MapOperations from "./core/operations/index.ts";
import BasePlugin from "./plugin/base.ts";

import "./assets/css/common.css";

interface MapGenOptions {
  zoom: number;
  paintOptions?: {
    onWarning?: (message: string) => void;
  };
  operaters?: Record<string, any>;
}

interface WorkerMessage {
  param: typeof param;
  constraints: {
    size: number;
    constraints: any;
  };
  buffers: any;
}
interface WorkerResponse {
  elapsed: number;
  numRiverTriangles: number;
  buffers: {
    quad_elements: Int32Array;
    a_quad_em: Float32Array;
    a_river_xyuv: Float32Array;
  };
}
interface RenderPayload {
  mesh: typeof this.mesh;
  t_peaks: typeof this.t_peaks;
  param: typeof param;
}

interface MeshData {
  mesh: any; // 根据实际类型定义
  t_peaks: any; // 根据实际类型定义
}

interface GenerateOptions {
  seed?: number;
  height?: number;
}

class MapGen {
  public renderer: Renderer;
  public painting: any;
  protected mesh: Mesh;
  protected t_peaks: any;

  private el: string;
  protected plugins: Map<string, BasePlugin> = new Map();

  private isInitialized: boolean = false;
  private readyPromise: Promise<void>;
  private readyResolver!: () => void;
  private zoom: number;
  private mapWorker: MapWorker;
  private constraints: any;
  private mapOperations: MapOperations;
  private isEditMode: boolean = false;
  private cb: () => void;
  private mapSlider: HTMLInputElement;

  constructor(el: string, options: MapGenOptions, constraints?: any) {
    this.el = el;
    this.readyPromise = new Promise((resolve) => {
      this.readyResolver = resolve;
    });

    this.init(options, constraints);
    this.use("sliders", Sliders);
  }

  private async init(options: MapGenOptions, constraints?: any): Promise<void> {
    try {
      // basic config
      this.initializeBasicConfig(options, constraints);
      // mesh data
      await this.initializeMeshData();

      // renderer
      this.initializeRenderer();

      // painting
      this.initializePainting();

      // map operations
      const { operaters } = options;
      this.initializeMapOperations(operaters);

      // worker
      this.initializeWorker();
      // plugins
      await this.initializePlugins();

      this.completeInitialization();
    } catch (error) {
      console.error("MapGen initialization failed:", error);
      throw error;
    }
  }
  // basic config
  private initializeBasicConfig(
    options: MapGenOptions,
    constraints?: any
  ): void {
    this.zoom = options.zoom;
    if (constraints && Object.keys(constraints).length > 0) {
      this.constraints = constraints;
    }
  }

  // mesh data
  private async initializeMeshData(): Promise<void> {
    const { mesh, t_peaks } = await makeMesh();
    this.mesh = mesh;
    this.t_peaks = t_peaks;
  }

  private initializePainting(): void {
    this.painting = new Painting(`${this.el}`, "map", this);
  }

  private initializeMapOperations(operaters: Record<string, any>): void {
    this.mapOperations = new MapOperations(
      document.querySelector(`#${this.el}`) as HTMLElement,
      operaters,
      this
    );

    this.mapSlider = this.mapOperations.getZoom().getSliderElement();

    this.mapOperations.getZoom().onWheeling((zoom, x, y) => {
      requestAnimationFrame(() => {
        this.renderer.setZoom(zoom);
        if (zoom <= 0.208) {
          this.renderer.setMousePosition(-500, -500);
        } else {
          if (x && y) {
            this.renderer.setMousePosition(x, y);
          }
        }
        const brushSize: BrushSize = this.painting
          .getBrush()
          .getSizeConfigByZoom(zoom);
        this.painting.getBrush().setSize(brushSize);
      });
    });
    this.mapOperations.getDrag().onMouseMove((x, y) => {
      this.renderer.setMousePosition(x, y);
    });
  }

  // renderer
  private initializeRenderer(): void {
    if (!this.el) {
      throw new Error("element container is required");
    }
    if (!this.mesh) {
      throw new Error("Mesh data not initialized");
    }
    this.renderer = new Renderer(this.el, this.mesh, {
      plugins: this.plugins,
      generate: this.generate.bind(this),
      setEditMode: this.setEditMode.bind(this),
    });
  }

  private initializeWorker(): void {
    if (!this.renderer) {
      throw new Error("Renderer not initialized");
    }
    this.mapWorker = new MapWorkerService(
      this.renderer,
      this.generate.bind(this)
    );
    this.mapWorker.createMap();
  }

  private async initializePlugins(): Promise<void> {
    if (this.plugins.size > 0) {
      await Promise.all(
        Array.from(this.plugins.values()).map((plugin) => plugin.onInit?.(this))
      );
    }
  }

  private completeInitialization(): void {
    this.isInitialized = true;
    this.readyResolver();
  }

  private use(name: string, plugin) {
    plugin.init?.(this);
    this.plugins.set(name, plugin);
  }

  public getMapSlider() {
    return this.mapSlider;
  }

  public generate(options?: GenerateOptions): void {
    if (!this.mapWorker.isWorking()) {
      const { seed } = options || {};

      if (seed) {
        param.elevation.seed = seed;
      }
      if (this.constraints) {
        this.painting.setElevation(this.constraints);
      } else {
        if (!this.isEditMode || seed) {
          this.painting.generateElevation(param.elevation);
        }
      }

      this.mapWorker.startWork();
      const payload: WorkerMessage = {
        param,
        constraints: {
          size: this.painting.CANVAS_SIZE,
          constraints: this.painting.elevation,
        },
        ...this.renderer.getTransferBuffers(),
      };
      const transferBuffers = this.renderer.getTransferBufferList();

      this.mapWorker.postMessage(
        WorkerMessageType.GENERATE,
        payload,
        transferBuffers
      );
    } else {
      this.mapWorker.requestWork();
    }
  }

  public async onReady(): Promise<void> {
    await this.readyPromise;
  }

  public start(): void {
    if (!this.isInitialized) {
      console.warn("MapGen is not ready for Render");
      return null;
    }

    if (!this._validateRenderParams()) {
      console.error("Invalid render parameters");
      return;
    }

    try {
      // prepare render data
      const payload: RenderPayload = {
        mesh: this.mesh,
        t_peaks: this.t_peaks,
        param,
      };

      // send render message
      if (this.mapWorker?.getWorker()) {
        this.mapWorker.postMessage(WorkerMessageType.INIT, payload);

        this.generate();
        this.renderer.setZoom(this.zoom);
      } else {
        throw new Error("Worker not available");
      }
    } catch (error) {
      console.error("Render failed:", error);
      this.handleRenderError(error);
    }
  }

  public setZoom(zoom: number) {
    this.zoom = zoom;
    this.renderer.setZoom(zoom);
  }
  public reload(): void {
    const seed = Math.floor(Math.random() * (1 << 30));
    this.generate({ seed });
  }

  private _validateRenderParams(): boolean {
    return !!(this.mesh && this.t_peaks && this.mapWorker?.getWorker());
  }

  public getRender(): Renderer {
    return this.renderer;
  }

  public getPainting(): Painting {
    return this.painting;
  }

  public getMapOperations() {
    return this.mapOperations;
  }

  public getPlugins() {
    return this.plugins;
  }

  public setEditMode(editMode: boolean) {
    this.isEditMode = editMode;
  }

  public isEdit(): boolean {
    return this.isEditMode;
  }

  public dispose(): void {
    try {
      // dispose worker
      if (this.mapWorker) {
        this.mapWorker.dispose();
      }
      // dispose renderer
      if (this.renderer) {
        this.renderer.dispose();
      }

      if (this.painting) {
        this.painting.dispose();
      }

      if (this.mapOperations) {
        this.mapOperations.dispose();
      }

      this.plugins.forEach((plugin) => plugin.onDestroy?.(this));
      this.plugins.clear();
    } catch (error) {
      console.error("Error disposing MapGen:", error);
    }
  }
}

export default MapGen;
