import { useEffect, useState } from 'react';
import { taskStatus } from '@/service/taskService';

interface Model3DTaskProgressProps {
    taskId: string;
    onComplete?: (success: boolean, status: number, duration: number) => void;
}

function Model3DTaskProgress({ taskId, onComplete }: Model3DTaskProgressProps) {
    const [progress, setProgress] = useState(0);
    const [waiting, setWaiting] = useState(0);
    const [startTime] = useState(Date.now());

    useEffect(() => {
        let isMounted = true;

        const fetchProgress = async () => {
            try {
                const response = await taskStatus(taskId);
                let status = parseInt(response.data.status);
                const queueAhead = parseInt(response.data.task_queue_ahead);
                
                if (!isMounted) return false;
               
                setWaiting(queueAhead || 0);
                
                if (status > 240 && status < 250) {
                    const duration = (Date.now() - startTime) / 1000;
                    onComplete?.(false, status, duration);
                    return true;
                }

                if (status === 210) {
                    setProgress(100);
                    const duration = (Date.now() - startTime) / 1000;
                    onComplete?.(true, status, duration);
                    return true;
                }
                
                setProgress(prev => {
                    const increment = 2;
                    const newProgress = Math.min(prev + increment, 99);
                    return newProgress;
                });
                
                return false;

            } catch (error) {
                if (isMounted) {
                    const duration = (Date.now() - startTime) / 1000;
                    onComplete?.(false, 500, duration);
                }
                return true;
            }
        };

        fetchProgress();

        const interval = setInterval(async () => {
            const shouldStop = await fetchProgress();
            if (shouldStop && isMounted) {
                clearInterval(interval);
            }
        }, 2000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [taskId, onComplete, startTime]);

    return (
        <>
            <div className="mx-2">
                <progress value={progress} max="100" className="w-60 model-progress"></progress>
            </div>
            {waiting > 0 ? (
                <span>Number of people waiting: {waiting}</span>
            ) : (
                <span>Generating...Estimated finish time 5 min.</span>
            )}
        </>
    );
}

export default Model3DTaskProgress;