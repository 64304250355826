// @ts-nocheck

/*
 * From https://www.redblobgames.com/maps/mapgen4/
 * Copyright 2018 Red Blob Games <redblobgames@gmail.com>
 * License: Apache v2.0 <http://www.apache.org/licenses/LICENSE-2.0.html>
 *
 * This module allows the user to paint constraints for the map generator
 */
"use strict";

/*
 * The painting interface uses a square array of elevations. As you drag the mouse it will paint filled circles into the elevation map,then send the elevation map to the generator to produce the output.
 *
 * 绘制界面使用正方形的高程数组。当您拖动鼠标时，它会将填充的圆圈绘制到高程图中，然后将高程图发送到生成器以生成输出。
 */

import { createNoise2D } from "simplex-noise";
//@ts-ignore
import { makeRandFloat } from "@redblobgames/prng";

// @ts-ignore
import VaillageManager from "./village.ts";
import Brush from "./brush.ts";
import {
  TERRAIN_TOOLS,
  MAP_CONFIG,
  NOISE_CONFIG,
} from "../../config/paint-config.ts";
import { BRUSH_SIZES_EVENT, BRUSH_SIZES } from "./const.ts";
// @ts-ignore
import { Pos } from "@/types";
import Renderer from "../render/index.ts";
import MapGen from "@/index.ts";

interface Size {
  innerRadius: number;
  outerRadius: number;
  rate: number;
}

interface Tool {
  elevation: number;
}

interface ElevationParam {
  seed: number;
  island: number;
}

interface CurrentStroke {
  previousElevation: Float32Array;
  time: Float32Array;
  strength: Float32Array;
}

/* The elevation is -1.0 to 0.0 → water, 0.0 to +1.0 → land */
// 海拔-1.0到0.0→水，0.0到+1.0→陆地
class Generator {
  private seed = 0;
  private island = 0;
  public userHasPainted = false;
  public elevation: Float32Array;
  public currentStroke: CurrentStroke;
  private canvas: HTMLElement;
  private mapEl: HTMLElement;
  private mask: HTMLElement;
  // @ts-ignore
  private cb;
  // @ts-ignore
  private render: Renderer;
  private dragging: boolean = false;
  private timestamp: number = 0;
  public brush: typeof Brush;
  public isAddDrawEvent: boolean = true;
  public actionType: string = "";
  public vaillagePos: Array<Pos> = [];
  public CANVAS_SIZE = MAP_CONFIG.CANVAS_SIZE;

  // @ts-ignore
  private vaillageManager: typeof VillageManager;
  private controls: { key: string; name: string; action: () => void }[] = [];
  private mapGen: MapGen;

  constructor(canvasElementId: string, mapElementId: string, mapGen: MapGen) {
    const getElement = <T extends HTMLElement>(id: string): T => {
      const element = document.getElementById(id);
      return element as T;
    };

    this.elevation = new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE);
    this.currentStroke = this.initCurrentStroke();
    this.canvas = getElement<HTMLElement>(canvasElementId);
    this.mapEl = getElement<HTMLElement>(mapElementId);
    this.mask = getElement<HTMLDivElement>("ui-mask");
    this.brush = Brush;
    this.mapGen = mapGen;
    this.render = mapGen.getRender();
    this.cb = mapGen.generate.bind(mapGen);
  }

  private initCurrentStroke() {
    return {
      /* elevation before the current paint stroke began */
      previousElevation: new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE),
      /* how long, in milliseconds, was spent painting */
      time: new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE),
      /* maximum strength applied */
      strength: new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE),
    };
  }

  public start(onWarning?: (message: string) => void) {
    this.vaillageManager = VaillageManager;
    this.vaillageManager.init(this.mapEl, onWarning);
    this._initListener();
  }

  private _initListener() {
    // this.brush.init(canvasElementId, mapElementId,cb,render);
    this.initControls();
    window.addEventListener("keydown", (e) => {
      for (let control of this.controls) {
        const { key, action } = control;
        if (e.key === key) {
          action();
          this?.displayCurrentTool();
        }
      }
    });

    for (let control of this.controls) {
      const { name, action } = control;
      //@ts-ignore
      document.getElementById(name)?.addEventListener("click", () => {
        action();
        if (
          this.isAddDrawEvent &&
          name !== "vaillage" &&
          name !== "deleteVaillage"
        ) {
          this.setUp("add");
          this.isAddDrawEvent = false;
        }
        this.mapGen.setEditMode(true);
        this.displayCurrentTool();
      });
    }
    this.setUp("add");
    this.displayCurrentTool();
  }
  private initControls() {
    const BRUSH_TOOLS = {
      q: { key: "q", name: "ocean", action: "currentTool" } as const,
      w: { key: "w", name: "shallow", action: "currentTool" } as const,
      e: { key: "e", name: "valley", action: "currentTool" } as const,
      r: { key: "r", name: "mountain", action: "currentTool" } as const,
      p: {
        key: "p",
        name: "vaillage",
        action: "currentTool",
        callback: () => this.addVaillage(),
      } as const,
      t: {
        key: "t",
        name: "deleteVaillage",
        action: "currentTool",
        callback: () => this.removeVaillage(),
      } as const,
      y: {
        key: "y",
        name: "clearVaillage",
        action: "currentTool",
        callback: () => this.clearVaillage(),
      } as const,
    } satisfies Record<
      string,
      {
        key: string;
        name: string;
        action: string;
        callback?: () => void;
      }
    >;

    this.controls = [
      ...Object.values(BRUSH_SIZES_EVENT).map(({ key, name }) => {
        return {
          key,
          name,
          action: () => {
            this.brush.setSize(name);
          },
        };
      }),
      // @ts-ignore
      ...Object.values(BRUSH_TOOLS).map(({ key, name, callback }) => {
        return {
          key,
          name,
          action: () => {
            this.brush.currentTool = name;
            callback?.();
          },
        };
      }),
    ];

    for (let control of this.controls) {
      const { name, action } = control;
      //@ts-ignore
      document.getElementById(name)?.addEventListener("click", () => {
        action();
        this.displayCurrentTool();
      });
    }
  }

  public displayCurrentTool() {
    const className = "current-control";
    for (let c of document.querySelectorAll("." + className)) {
      c.classList.remove(className);
    }
    //@ts-ignore
    document.getElementById(this.brush.currentTool)?.classList.add(className);
    //@ts-ignore
    document.getElementById(this.brush.currentSize)?.classList.add(className);
  }

  public setUp(action: "add" | "remove") {
    if (action === "add") {
      this.mapGen.setEditMode(true);
    }

    type EventMap = {
      pointerdown: PointerEvent;
      pointerup: PointerEvent;
      pointercancel: PointerEvent;
      pointermove: PointerEvent;
      touchstart: TouchEvent;
    };

    const events: Array<[keyof EventMap, EventListener]> = [
      // @ts-ignore
      ["pointerdown", this.handleStart],
      // @ts-ignore
      ["pointerup", this.handleEnd],
      // @ts-ignore
      ["pointercancel", this.handleEnd],
      // @ts-ignore
      ["pointermove", this.handleMove],
      // @ts-ignore
      ["touchstart", this.preventTouchStart],
    ];

    events.forEach(([event, handler]) => {
      this.canvas[
        action === "add" ? "addEventListener" : "removeEventListener"
      ](event, handler);
    });

    if (action === "add" || this.actionType === "delete") {
      this.mapEl.removeEventListener(
        "click",
        VaillageManager.addVillageHandler
      );
    }
  }

  private handleStart = (event: PointerEvent) => {
    if (!this.mapGen.isEdit()) {
      return;
    }

    event.stopPropagation();
    if (event.button !== 0) return;

    this.canvas.setPointerCapture(event.pointerId);

    this.dragging = true;
    this.timestamp = Date.now();
    this.currentStroke.time.fill(0);
    this.currentStroke.strength.fill(0);
    this.currentStroke.previousElevation.set(this.elevation);
    this.handleMove(event);
  };
  //@ts-ignore
  private handleEnd = () => {
    this.dragging = false;
  };

  private handleMove = (event: PointerEvent) => {
    if (!this.dragging) return;

    const nowMs = Date.now();
    const bounds = this.canvas.getBoundingClientRect();
    let coords: [number, number] = [
      (event.x - bounds.left) / bounds.width,
      (event.y - bounds.top) / bounds.height,
    ];

    // coords = Painting.screenToWorldCoords(coords);
    let out = this.render.screenToWorld(coords);
    coords = [out[0] / 1000, out[1] / 1000];
    //@ts-ignore
    let brushSize = BRUSH_SIZES[this.brush.currentSize];
    if (event.pointerType === "pen" && event.pressure !== 0.5) {
      let radius = 2 * Math.sqrt(event.pressure);
      brushSize = {
        ...brushSize,
        innerRadius: Math.max(1, brushSize.innerRadius * radius),
        outerRadius: Math.max(2, brushSize.outerRadius * radius),
      };
    }
    if (event.shiftKey) {
      // Hold down shift to paint slowly
      brushSize = { ...brushSize, rate: brushSize.rate / 4 };
    }
    //@ts-ignore
    this.paintAt(
      TERRAIN_TOOLS[this.brush.getTool()],
      coords[0],
      coords[1],
      brushSize,
      nowMs - this.timestamp
    );
    this.timestamp = nowMs;
    // this.updateUI();
    if (this.cb) this.cb();
  };

  public onPaintingStart(cb: () => void) {
    this.onPaintingStartCallback = cb;
  }

  public onPaintingEnd(cb: () => void) {
    this.onPaintingEndCallback = cb;
  }

  // private  updateUI() {
  //     let userHasPainted = this.userHasPainted();
  //     (document.querySelector("#slider-seed input") as HTMLInputElement).disabled = userHasPainted;
  //     (document.querySelector("#slider-island input") as HTMLInputElement).disabled = userHasPainted;
  //     (document.querySelector("#button-reset") as HTMLInputElement).disabled = !userHasPainted;
  // }

  private preventTouchStart = (event: TouchEvent) => {
    event.preventDefault();
  };

  public getBrush(): typeof Brush {
    return this.brush;
  }

  public addVaillage(): void {
    this.setUp("remove");
    this.isAddDrawEvent = true;
    this.mapEl.addEventListener(
      "click",
      this.vaillageManager.addVillageHandler
    );
  }

  // 替换原来的 removeVaillage 方法
  public removeVaillage(): void {
    this.actionType = "delete";
    this.setUp("remove");
    this.vaillageManager.enableVillageRemoval();
  }

  public onUpdate() {
    this.cb();
  }
  //@ts-ignore
  public clearVaillage(): void {
    this.vaillageManager.clear();
  }

  // 获取村庄位置的新方法
  public getVaillagePositions(): Array<Pos> {
    return this.vaillageManager.getVaillage();
  }

  public getUserHasPainted() {
    return this.userHasPainted;
  }

  public disableAction() {
    this.mask.classList.add("ui-mask");
  }

  public enableAction() {
    this.mask.classList.remove("ui-mask");
  }

  public getElevation() {
    return this.elevation;
  }

  public setElevation(elevation: any): void {
    this.elevation = elevation;
  }
  //@ts-ignore
  public generateElevation(elevationParam: ElevationParam): void {
    //   if ((elevationParam.seed !== this.seed || elevationParam.island !== this.island) || heightOffset !== 0) {
    this.seed = elevationParam.seed;
    this.island = elevationParam.island;
    this.generate();
    //  }
  }

  /** Use a noise function to determine the shape */
  private generate() {
    const { elevation, island } = this;
    const noise2D = createNoise2D(makeRandFloat(this.seed));
    // 持久性是用于控制噪声图的平滑度和细节的参数。
    const persistence = NOISE_CONFIG.PERSISTENCE;
    //在生成噪声时，振幅用于控制噪声的“高度”或“对比度”。较大的振幅会增加噪声值的范围，使得生成的图像有更多的对比，而较小的振幅会让噪声图平滑且不明显。
    const amplitudes = Array.from(
      { length: NOISE_CONFIG.OCTAVES },
      (_, octave) => Math.pow(persistence, octave)
    );
    //@ts-ignore
    // fbm_noise函数通过叠加不同频率的噪声值来生成一个平滑的噪声图。
    // 频率是根据octaves和persistence计算的，频率越高，噪声图的细节越丰富。
    // 分形布朗运动（FBM）实现:
    function fbm_noise(nx, ny) {
      let sum = 0,
        sumOfAmplitudes = 0;
      for (let octave = 0; octave < amplitudes.length; octave++) {
        let frequency = 1 << octave;
        //noise2D(x,y)生成一个坐标上的噪音值
        sum += amplitudes[octave] * noise2D(nx * frequency, ny * frequency);
        sumOfAmplitudes += amplitudes[octave];
      }
      return sum / sumOfAmplitudes;
    }

    //主要地形生成循环
    for (let y = 0; y < this.CANVAS_SIZE; y++) {
      for (let x = 0; x < this.CANVAS_SIZE; x++) {
        let p = y * this.CANVAS_SIZE + x;
        // 将坐标映射到-1到1之间
        let nx = (2 * x) / this.CANVAS_SIZE - 1,
          ny = (2 * y) / this.CANVAS_SIZE - 1;
        // 计算坐标到原点的距离
        let distance = Math.max(Math.abs(nx), Math.abs(ny));
        // 生成基础地形
        let heightOffset = 0.1;

        let e =
          0.5 *
            (fbm_noise(nx, ny) + island * (0.75 - 2 * distance * distance)) +
          heightOffset;
        // 确保地形高度在允许的范围内
        if (e < MAP_CONFIG.ELEVATION.MIN) {
          e = MAP_CONFIG.ELEVATION.MIN;
        }
        if (e > MAP_CONFIG.ELEVATION.MAX) {
          e = MAP_CONFIG.ELEVATION.MAX;
        }
        elevation[p] = e;
        // 如果高程大于水陆分界线，则生成山地
        // 山地生成逻辑：
        if (e > MAP_CONFIG.ELEVATION.WATER_THRESHOLD) {
          // 生成山地噪声
          //0.5 * (第一层噪声) + 0.5 * (第二层噪声)
          let m =
            0.5 *
              noise2D(
                nx + NOISE_CONFIG.MOUNTAIN_NOISE_OFFSET.X,
                ny + NOISE_CONFIG.MOUNTAIN_NOISE_OFFSET.Y
              ) +
            0.5 *
              noise2D(
                2 * nx + NOISE_CONFIG.MOUNTAIN_NOISE_SCALE.X,
                2 * ny + NOISE_CONFIG.MOUNTAIN_NOISE_SCALE.Y
              );
          // TODO: make some of these into parameters
          // 计算山地高度
          let mountain =
            Math.min(
              MAP_CONFIG.ELEVATION.MAX,
              e * MAP_CONFIG.MOUNTAIN.ELEVATION_MULTIPLIER
            ) *
            (1 - Math.abs(m) / MAP_CONFIG.MOUNTAIN.STRENGTH_THRESHOLD);
          // 如果山地高度大于水陆分界线，则更新地形高度
          if (mountain > MAP_CONFIG.ELEVATION.WATER_THRESHOLD) {
            elevation[p] = Math.max(
              e,
              Math.min(e * MAP_CONFIG.MOUNTAIN.MAX_ELEVATION, mountain)
            );
          }
        }
      }
    }
    this.userHasPainted = false;
  }

  /**
   * Paint a circular region. x0, y0 should be 0 to 1
   * 绘制一个圆形区域。x0， y0应该是0到1
   */
  private paintAt(
    tool: Tool,
    x0: number,
    y0: number,
    size: Size,
    deltaTimeInMs: number
  ): void {
    let { elevation } = this;

    /* This has two effects: first time you click the mouse it has a strong effect, and it also limits the amount in case you pause
     *
     *  */
    // 限制时间间隔
    deltaTimeInMs = Math.min(100, deltaTimeInMs);

    let newElevation = tool.elevation;
    // 获取圆的半径和强度
    let { innerRadius, outerRadius, rate } = size;
    // 获取圆心坐标
    let xc = (x0 * this.CANVAS_SIZE) | 0,
      yc = (y0 * this.CANVAS_SIZE) | 0;
    // 获取圆的范围
    let top = Math.ceil(Math.max(0, yc - outerRadius)),
      bottom = Math.floor(Math.min(this.CANVAS_SIZE - 1, yc + outerRadius));
    for (let y = top; y <= bottom; y++) {
      let s = Math.sqrt(outerRadius * outerRadius - (y - yc) * (y - yc)) | 0;
      let left = Math.max(0, xc - s),
        right = Math.min(this.CANVAS_SIZE - 1, xc + s);
      for (let x = left; x <= right; x++) {
        let p = y * this.CANVAS_SIZE + x;
        // 计算像素到圆心的距离
        let distance = Math.sqrt((x - xc) * (x - xc) + (y - yc) * (y - yc));
        // 计算强度
        let strength =
          1.0 -
          Math.min(
            1,
            Math.max(0, (distance - innerRadius) / (outerRadius - innerRadius))
          );
        // 计算强度衰减，越靠近中心强度越大
        let factor = (rate / 1000) * deltaTimeInMs;
        // 更新时间
        this.currentStroke.time[p] += strength * factor;
        // 更新强度
        if (strength > this.currentStroke.strength[p]) {
          this.currentStroke.strength[p] =
            (1 - factor) * this.currentStroke.strength[p] + factor * strength;
        }
        let mix =
          this.currentStroke.strength[p] *
          Math.min(1, this.currentStroke.time[p]);
        elevation[p] =
          (1 - mix) * this.currentStroke.previousElevation[p] +
          mix * newElevation;
      }
    }

    this.userHasPainted = true;
  }

  public stop() {
    this.setUp("remove");
    this.dragging = false;
    this.isAddDrawEvent = true;
  }

  public resume() {
    //this.setUp('add');
    this.dragging = true;
  }

  public dispose() {
    this.setUp("remove");
    this.clearVaillage();
  }
}
export default Generator;
