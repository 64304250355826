import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import MapSelector, { MapOption, } from "@/components/MapSelector";
import GenerateBtn from "@/components/GenerateBtn";
import { getShowcaseList, createShowcaseTask, taskRender } from "@/service/taskService";
import { useMount } from "ahooks";
import backIcon from "@/assets/back.png";
import ImageCarousel from "./ImageCarousel";
import { sortByIndex } from "./config";

function ShowCases() {
    const navigate = useNavigate();
    const [selectedTown, setSelectedTown] = useState<MapOption>({ id: "", cover: "", title: "", media: [], index: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showcaseList, setShowcaseList] = useState<MapOption[]>([]);
    const [carouselKey, setCarouselKey] = useState<number>(0);

    useMount(() => {
        getShowcaseList().then(res => {
            console.log({ res: res.data.list });
            let list = res.data.list;
            
            list = sortByIndex(list);
            setShowcaseList(list);
            if (list && list.length > 0) {
                const firstItem = list[0];
                setSelectedTown({
                    id: firstItem.id,
                    cover: firstItem.cover,
                    title: firstItem.title,
                    media: firstItem.media,
                    index: 0
                });
            }
        }).catch(() => {
            message.error("Error in getting showcase list!");
        }).finally(() => {

        })
    })

    // 快速生成UE场景
    const generateUEScene = async () => {
        try {
            const response = await createShowcaseTask(selectedTown.id);
            const { task_id, status } = response.data;
            console.log({ task_id, status });
            if (status === 900) {
                await taskRender(task_id);
            }
            setIsLoading(false);
            navigate(`/uepreview/${task_id}?scene=${selectedTown.title}`);
        } catch (error) {
            message.error("Error in generating UE scene!");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <div className=" flex items-center mb-10">
                <Link to="/" className="flex items-center cursor-pointer">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className="ml-10">Examples</h2>
            </div>
            <div className="flex gap-4">
                <div className="flex flex-col gap-4">
                    <MapSelector
                        title="Choose a scene"
                        options={showcaseList}
                        value={selectedTown.id}
                        onChange={(id, cover, title, media, index) => {
                            setSelectedTown({ id, cover, title, media, index });
                            setCarouselKey(prev => prev + 1);
                        }}
                    />
                    <GenerateBtn
                        text="Load the scene"
                        isLoading={isLoading}
                        onClick={() => {
                            console.log({ selectedTown });
                            if (selectedTown.id === "") {
                                message.warning("Please select a map and a town.");
                                return
                            }
                            setIsLoading(true);
                            generateUEScene();
                        }}
                    />
                </div>
                <div className="flex-1 w-full rounded-md bg-[#191919] text-[#5E5E5E] p-4 min-h-[330px] flex items-center justify-center">
                    {selectedTown.media.length > 0 ? (
                        <ImageCarousel 
                            key={carouselKey} 
                            images={selectedTown.media} 
                        />
                    ) : (
                        <div className="text-white opacity-20">No image</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ShowCases;